import React from "react";
import logo from "../../../assests/logo.png"
import headerLogo from "../../../assests/logo.png"
import { useTranslation } from "react-i18next";

const LogoutPopup = ({userLogOut, showLogoutModal, setShowLogoutModal})=>{
    const { t, i18n } = useTranslation();
    return <div className={`modal fade address_modal ${showLogoutModal ? "show" : ''}`} data-bs-backdrop="static" style={{ background: showLogoutModal ? 'rgba(0,0,0,0.5)' : '', display: showLogoutModal ? "block" : "none" ,zIndex:10000}}>
    
    <div className="modal-dialog modal-dialog-centered modal-lg modal_custom_width">
        <div className="modal-content py-2" style={{position:'relative'}}>
            <div style={{position:'absolute', top:'0', zIndex:'100',right:'50%',height:'120px',borderRadius:'50%',overflow:'hidden',backgroundColor:'#ffffff',padding:5,
            transform: 'translate(50%, -44%)'
        }} >
                 <img src={logo} style={{ padding: '20px 18px 0',
    width:' 122px'
    }}   alt="logo" />
            </div>
            <div className="modal-body p-0 py-4 px-4">
            <div className="d-flex align-items-center flex-column mt-5">
                {/* <img src={headerLogo} height="180" alt="logo" /> */}
                <span className="font_basic" style={{color:"#3498db",letterSpacing:2}}>{t("Thanks")}</span>
            </div>
            <p className="m-0 mt-3 text-secondary text-center text-dark font_basic">{t("confirm ")} <span className="text-dark font_basic">{t("LogOut")}</span> ?</p>
            </div>
            <div className="px-4 d-flex justify-content-center ">

            <button
                type="button"
                style={{ backgroundColor:'#adb5bd'}}
                className={` text-black px-2 py-2 mb-2  rounded w-3/12 font_basic`}
                onClick={()=>{setShowLogoutModal(false)}}
            >
               {t("Cancel")}
            </button>
            <button
                type="button"
                style={{ backgroundColor:'#3498db'}}
                className={`text-white px-2 py-2 mb-2 ms-3 rounded w-3/12`}
                onClick={userLogOut}
            >
                {t("LogOut")}
            </button>
            </div>
        </div>
    </div>
</div>
}

export default LogoutPopup ;